import { AllUsersActionTypes } from "./types";

// common success
export const usersApiResponseSuccess = (actionType: string, data: any) => ({
  type: AllUsersActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const usersApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: AllUsersActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getUsers = () => {
  return {
    type: AllUsersActionTypes.GET_USERS,
  };
};

