import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

// 
const getAdmins = () => {
  return api.get(url.GET_ADMINS);
};


const toggleFavouriteContact = (id: string | number) => {
  return api.update(url.TOGGLE_FAVOURITE_CONTACT + id  + "/", {
    params: { id },
  });
};




// Conversations
// const createConversation = (data: object) => {
//   return api.create(url.CREATE_CONVERSATION, data);
// };
const getChatUserDetails = (id: string | number) => {
  return api.get(url.GET_CHAT_USER_DETAILS + id + "/");
};

// const getChatUserConversations = () => {
//   return api.get(url.GET_CHAT_USER_CONVERSATIONS);     {  params: { id }}
// };
const getChatUserConversations = (id: string | number) => {
  return api.get(url.GET_CHAT_USER_CONVERSATIONS + id + "/");
};

const receiveMessageFromUser = (id: string | number) => {
  return api.get(url.RECEIVE_MESSAGE_FROM_USER + id + "/", {
    params: { id },
  });
};

//  Messages
const sendMessage = (data: any) => {
  return api.create(url.SEND_MESSAGE, data);
};


const forwardMessage = (data: object) => {
  return api.create(url.FORWARD_MESSAGE, data);
};


const readMessage = (id: string | number) => {
  return api.update(url.READ_MESSAGE + id + "/", { params: { id } });
};
const messageReceived = (id: string | number) => {
  return api.update(url.MESSAGE_RECEIVED + id + "/", { params: { id } });
};

const deleteMessage = (userId: number | string, messageId: number | string) => {
  return api.delete(url.DELETE_MESSAGE + messageId + "/");
}; 


const deleteUserMessages = (userId: number | string) => {
  return api.delete(url.DELETE_USER_MESSAGES + userId + "/");
};


/*
archive
*/
const getArchiveContact = () => {
  return api.get(url.GET_ARCHIVE_CONTACT);
};

const toggleArchiveContact = (id: string | number) => {
  return api.update(url.TOGGLE_ARCHIVE_CONTACT + "/" + id, { params: { id } });
};

const readConversation = (conversationData: any) => {
  return api.update(url.READ_CONVERSATION + conversationData.conversationId + "/", conversationData);
};

const deleteImage = (
  userId: number | string,
  messageId: number | string,
  imageId: number | string
) => {
  return api.delete(url.DELETE_IMAGE + "/" + userId + "/" + messageId, {
    params: { userId, messageId, imageId },
  });
};

export {
  getAdmins,
  // createConversation,
  getChatUserDetails,
  getChatUserConversations,
  sendMessage,
  messageReceived,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  forwardMessage,
  deleteUserMessages,
  toggleFavouriteContact,
  getArchiveContact,
  toggleArchiveContact,
  readConversation,
  deleteImage,
};
