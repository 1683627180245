import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { api } from "../config";

// default
axios.defaults.baseURL = api.API_URL;

// Content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// Get the CSRF token from the meta tag or cookie
const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
axios.defaults.headers.common['X-CSRFToken'] = csrfToken ?? '';

let isRefreshing = false;
let failedRequestsQueue: Array<{ resolve: (value?: unknown) => void; reject: (reason?: any) => void }> = [];

// Function to set withCredentials dynamically
const setWithCredentials = (value: boolean): void => {
  axios.defaults.withCredentials = value;
};

const processQueue = (error: any, token: string | null = null) => {
  failedRequestsQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });
  failedRequestsQueue = [];
};

const handleTokenRefresh = async (): Promise<void> => {
  try {
    await axios.post("/token/refresh/", {}, { withCredentials: true });
    processQueue(null);
  } catch (error) {
    processQueue(error);
    throw error;
  }
};

// Interceptor to handle access token expiration
axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: any) => {
    const originalRequest = error.config;

    // Handle undefined errors
    if (typeof error.response === 'undefined') {
      alert('A server/network error occurred.');
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      // Check if the request is for token refresh
      if (originalRequest.url.includes("/token/refresh/")) {
        showModal();
        setTimeout(() => {
          window.location.href = "/login/";
        }, 2000);
        return Promise.reject(error);
      }

      if (!isRefreshing) {
        isRefreshing = true;
        originalRequest._retry = true;

        try {
          await handleTokenRefresh();
          isRefreshing = false;

          // Retry the original request with the new tokens
          return axios(originalRequest);
        } catch (refreshError) {
          isRefreshing = false;
          showModal();
          setTimeout(() => {
            window.location.href = "/login/";
          }, 2000);
          return Promise.reject(refreshError);
        }
      } else {
        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            resolve: () => {
              resolve(axios(originalRequest));
            },
            reject: (err: any) => {
              reject(err);
            }
          });
        });
      }
    }

    // Handle other errors
    if (error.response.status === 403) {
      // Handle 403 (Forbidden) errors
    } else if (error.response.status === 500) {
      alert("An internal server error occurred. Please try again later.");
    }

    return Promise.reject(error);
  }
);

class APIClient {
  get = (url: string, params?: Record<string, any>): Promise<AxiosResponse> => {
    let response: Promise<AxiosResponse>;
    let paramKeys: string[] = [];

    if (params) {
      Object.keys(params).forEach(key => {
        paramKeys.push(`${key}=${params[key]}`);
      });

      const queryString = paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, { withCredentials: true });
    } else {
      response = axios.get(url, { withCredentials: true });
    }

    return response;
  };

  create = (url: string, data: any): Promise<AxiosResponse> => {
    return axios.post(url, data, { withCredentials: true });
  };

  update = (url: string, data: any): Promise<AxiosResponse> => {
    return axios.patch(url, data, { withCredentials: true });
  };

  put = (url: string, data: any): Promise<AxiosResponse> => {
    return axios.put(url, data, { withCredentials: true });
  };

  delete = (url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
    return axios.delete(url, { ...config, withCredentials: true });
  };
}

// Function to show the modal
let showModal = (): void => {};
export const setShowModalFunction = (fn: () => void): void => {
  showModal = fn;
};

export { APIClient, setWithCredentials };