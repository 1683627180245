import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// hooks
import { useRedux } from "../../hooks/index";
import withRouter from "../../components/withRouter";

// actions
import { logoutUser } from "../../redux/actions";

const Logout = (props: any) => {
  // global store
  const { dispatch } = useRedux();
  const navigate = useNavigate();

  useEffect(() => {
    // Dispatch the logout action
    dispatch(logoutUser());

    // Use navigate from props to redirect to '/login'
    navigate('/login');
  }, [dispatch, navigate]);

  return null;
};

export default withRouter(Logout);
