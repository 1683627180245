import React from "react";

// interface
import { ImageTypes, MessagesTypes } from "../../../data/datatypes";

// hooks
import { useRedux } from "../../../hooks/index";

interface RepliedMessageProps {
  isFromMe: boolean;
  message: MessagesTypes;
  replierFullname: string;     //  !!!!  replierFullname may be changed to include chatUserDetails for non isChannel(!isChannel) chats 
}

function RepliedMessage({ isFromMe, message, replierFullname }: RepliedMessageProps) {
  const { useAppSelector } = useRedux();

  const { users } = useAppSelector((state: any) => ({
      users: state.AllUsers.users,
    })
  );
  

  const isReplyFromMe = message.meta.sender + "" === users.id + "";
  return (
    <div className="">
      <div className="replymessage-block mb-2 d-flex align-items-start">
        <div className="flex-grow-1">
          <h5 className="conversation-name">
            {isReplyFromMe ? "You" : replierFullname}
          </h5>

          {message.replyOf?.text && (
            <p className="mb-0">{message.replyOf?.text}</p>
          )}

          {/*{(message.replyOf?.attachments) && (
            <p className="mb-0">
              {message.replyOf?.attachments && !message.replyOf?.image && `${message.replyOf?.attachments.name}`}
              {message.replyOf?.image && !message.replyOf?.attachments && `${message.replyOf?.image.name}`}
              {message.replyOf?.image && message.replyOf?.attachments && `${message.replyOf?.attachments.name} & ${message.replyOf?.image.name}`}
            </p>
          )} */}

          {(message.replyOf?.attachments) && (
            <p className="mb-0 d-flex, d-block">
              {message.replyOf.attachments.map((attachment, index) => (
                <span key={index} className="d-flex d-block p-3 border-primary border rounded-3 m-1">
                  {attachment.name}
                  {index < message.replyOf.attachments.length - 1 && ', '}
                </span>
              ))}
              {message.replyOf?.image && !message.replyOf?.attachments && ` ${message.replyOf.image}`}
              {message.replyOf?.image && message.replyOf?.attachments && ` & ${message.replyOf.image}`}
            </p>
          )}
          
          

          {(message.replyOf?.newimage) && (
            <div className="ctext-wrap">
              <div className="message-img mb-0">
                <div className="message-img-list">
                  <div>
                    <a className="popup-img d-inline-block" href="/dashboard">
                      <img src={message.replyOf?.newimage[0].downloadLink} alt="" className="rounded border" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          
        </div>
      </div>
    </div>
  );
}

export default RepliedMessage;
