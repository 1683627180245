import React, { useEffect } from "react";
import classnames from "classnames";
//import { useRedux } from "../../hooks/index";
import ConversationUser from "./ConversationUser";


interface IndexProps {}
const Index = (props: IndexProps) => {


  return (
    <>
      <div
        className={classnames("user-chat", "w-100", "overflow-hidden", {
          "user-chat-show": true,
        })}
        id="user-chat"
      >
        <div className="user-chat-overlay" id="user-chat-overlay"></div>
          <div className="chat-content d-lg-flex">
            <div className="w-100 overflow-hidden position-relative">
              <ConversationUser
                chatUserDetails={undefined}
              />
            </div>
          </div>
      </div>
    </>
  );
};

export default Index;
