import React, { useEffect } from "react";
import { useRedux  } from "../../../hooks/index";
import ChatUser from "./ChatUser";


interface AdminsProps {
  onlineUsers: Record<number | string, boolean>;
  selectedChat: string | number;
  onSelectChat: (id: number | string) => void;
}
const Admins = ({ onlineUsers, selectedChat, onSelectChat }: AdminsProps) => {
  const { useAppSelector } = useRedux();

  const { admins } = useAppSelector(state => ({
    admins: state.Chats?.admins?.data[0],     // remove the [0] in the future to handle multiple admins and to switch from admin to admin
  }));
  // console.log("admins in Admins", admins)
  // const onAdminId = admins?.map((admin: any) => admin.id === isOnline);   //  just an idea for switching from disconnnected admin to an online admin. The isOnline can be taken from isOnline status websocket
  // const onAdminId = admins?.map((admin: any) => admin.id);
  const onAdminId = admins?.id;
  // console.log("onAdminId", onAdminId)

  useEffect(() => {
    // if (admins && admins.length > 0) {
    //   onSelectChat(onAdminId);
    // }
    if (onAdminId) {
      onSelectChat(onAdminId);
    }
  }, [onSelectChat, onAdminId]);

  // Check if admins exist and if it's an array
  // if (!admins || !Array.isArray(admins)) {
  //   return <div></div>;
  // }
  if (!admins) { return <div></div>; }
  
  return (
    <>
      <div className="chat-message-list">
        <ul className="list-unstyled chat-list chat-user-list">
          {/* {(admins || []).map((admin: any, key: number) => (
          //   <ChatUser
          //     admin={admin}
          //     key={key}
          //     isOnline={onlineUsers[admin.id] || false}  // <-- pass the online status of this user
          //     selectedChat={selectedChat}
          //     onSelectChat={onSelectChat}
          //   />
          // ))}  */}
          <ChatUser
            admin={admins}
            isOnline={onlineUsers[admins.id] || false}  // <-- pass the online status of this user
            selectedChat={selectedChat}
            onSelectChat={onSelectChat}
          />
        </ul>
      </div>
    </>
  );
};

export default Admins;
