import React from "react";
import { Alert, Row, Col, Form, Button } from "reactstrap";
import { useRedux } from "../../hooks/index";
import { shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { registerUser } from "../../redux/actions";
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";


interface RegisterProps {}
const Register = (props: RegisterProps) => {
  const navigate = useNavigate(); // <-- Add this
  const { dispatch, useAppSelector } = useRedux();

  const { registrationError, regLoading } = useAppSelector(state => ({
    registrationError: state.Register.registrationError,
    regLoading: state.Register.loading,
  }),
  shallowEqual
);

  const resolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .email("This value should be a valid email.")
        .required("Please Enter E-mail."),
      first_name: yup.string().required("Please Enter first_name."),
      last_name: yup.string().required("Please Enter last_name."),
      //  
    })
  );

  const defaultValues: any = {};

  const methods = useForm({ defaultValues, resolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  const onSubmitForm = (values: object) => {
    const updatedValues = { 
      ...values,
      is_external: true,
  };
    dispatch(registerUser(updatedValues, navigate));
  };

  return (
    <NonAuthLayoutWrapper>
      <Row className=" justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-4">
          <div className="py-md-5 py-4">
            <AuthHeader
              title="Welcome to Metaversity AI"
            />

           

            {registrationError && registrationError ? (
              <Alert color="danger">{registrationError}</Alert>
            ) : null}

            <Form
              onSubmit={handleSubmit(onSubmitForm)}
              className="position-relative"
            >
              {regLoading && <Loader />}

              <div className="mb-3">
                <FormInput
                  label="First Name"
                  type="text"
                  name="first_name"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter first name"
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <FormInput
                  label="Last Name"
                  type="text"
                  name="last_name"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter last_name"
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <FormInput
                  label="Email"
                  type="text"
                  name="email"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter Email"
                  className="form-control"
                />
              </div>

              {/*<div className="mb-3">
                <FormInput
                  label="Password"
                  type="password"
                  name="password"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  className="form-control pe-5"
                  placeholder="Enter Password"
                />
              </div> */}

              {/*<div className="mb-4">
                <p className="mb-0">
                  By registering you agree to the MetaversityAI{" "}
                  <Link to="#" className="text-primary">
                    Terms of Use
                  </Link>
                </p>
              </div> */}

              <div className="text-center mb-3">
                <Button
                  color="primary"
                  className="w-100  waves-effect waves-light"
                  type="submit"
                >
                  Start The Chat
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default Register;
