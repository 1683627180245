import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getUsers = () => {
  return api.get(url.GET_USERS);
};


export { getUsers };
