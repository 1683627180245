//redux
import { TypedUseSelectorHook, useSelector, useDispatch, shallowEqual } from "react-redux";
import type { RootState, AppDispatch } from "../redux/store";

const useRedux = () => {
  const dispatch: AppDispatch = useDispatch();

  // const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
  
  // Define useAppSelector with shallow equality check
  const useAppSelector: TypedUseSelectorHook<RootState> = (selector) => 
    useSelector(selector, shallowEqual);

  return { dispatch, useAppSelector };
};

export { useRedux };
