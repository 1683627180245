import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//  Redux States
import { AllUsersActionTypes } from "./types";
import {
  usersApiResponseSuccess,
  usersApiResponseError,
} from "./actions";

// api
import { getUsers as getUsersApi } from "../../api/index";


function* getUsers() {
  try {
    const response: Promise<any> = yield call(getUsersApi);
    yield put(
      usersApiResponseSuccess(AllUsersActionTypes.GET_USERS, response)
    );
  } catch (error: any) {
    yield put(
      usersApiResponseError(AllUsersActionTypes.GET_USERS, error)
    );
  }
}

export function* watchGetUsers() {
  yield takeEvery(AllUsersActionTypes.GET_USERS, getUsers);
}

function* contactsSaga() {
  yield all([ fork(watchGetUsers) ]);
}

export default contactsSaga;
