import React, { useEffect, useState, useRef, useCallback } from "react";
import { useRedux } from "../../../hooks/index";
import { api } from "../../../config";
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";
import { MessagesTypes } from "../../../data/datatypes";
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import {
  getChatUserConversations,
  // receiveMessageFromUser,
  deleteMessage,
  deleteUserMessages,
} from "../../../redux/actions";


interface IndexProps {
  chatUserDetails: any;
}

const Index = (props: IndexProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const {
    users,
    admins,
    chatUserDetails,
    chatUserConversations,
    isUserMessageSent,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
  } = useAppSelector(state => ({
    users: state.AllUsers.users?.data[0],
    admins: state.Chats.admins?.data[0],
    chatUserDetails: state.Chats.chatUserDetails,
    chatUserConversations: state.Chats.chatUserConversations?.data,
    isUserMessageSent: state.Chats.isUserMessageSent,
    isMessageDeleted: state.Chats.isMessageDeleted,
    isMessageForwarded: state.Chats.isMessageForwarded,
    isUserMessagesDeleted: state.Chats.isUserMessagesDeleted,
    isImageDeleted: state.Chats.isImageDeleted,
  }));
  const [chatConversation, setChatConversation] = useState(null);

  // console.log("users in ConversationUser Index", users)
  // console.log("from ConversationUser Index -- chatUserDetails", chatUserDetails)
  // console.log("from ConversationUser Index -- chatUserConversations", chatUserConversations)
  // console.log("from ConversationUser Index -- chatConversation", chatConversation)

  useEffect(() => {
    setChatConversation(chatUserConversations)
  }, [chatUserConversations])

  const [replyData, setReplyData] = useState<null | MessagesTypes | undefined>();
  const onSetReplyData = (reply: null | MessagesTypes | undefined) => {
    setReplyData(reply);
  };

  const conversationId = users?.id;
  // console.log("conversationId in ConversationUser Index", conversationId)
  const socketClientRef = useRef<W3CWebSocket | null>(null);
  const MAX_RETRIES = 5;

  const connectWebSocket = useCallback(() => {
    if (!conversationId) return;  // Ensure conversationId is available

    let retries = 0;

    const handleOpen = () => {
      // console.log('Message WebSocket client connected');
      retries = 0;
    };

    const handleMessage = (message: any) => {
      // console.log('+++++++ row message handleMessage', message);
      const receivedData = JSON.parse(message.data);
      // console.log('+++++++ receivedData', receivedData);

      // if (receivedData.message) {
      //   dispatch(receiveMessageFromUser(receivedData.message));
      //   console.log('-------- receivedData.message', receivedData.message);
      // }
      if (message.type === 'message') {
        // dispatch(receiveMessageFromUser(receivedData.message));
        setChatConversation(receivedData.message)
      }
    };

    const handleError = (error: any) => {
      console.error('WebSocket error:', error);
    };

    const handleClose = () => {
      console.log('WebSocket Closed! Attempting to reconnect...');
      if (retries < MAX_RETRIES) {
        retries++;
        setTimeout(connectWebSocket, retries * 1000);
      } else {
        console.error("Max retries reached. Not attempting to reconnect.");
      }
    };

    const wsUrl = `${api.REACT_APP_WEBSOCKET_URL}/ws/chat/${conversationId}/`;
    socketClientRef.current = new W3CWebSocket(wsUrl);
    //socketClientRef.current = new W3CWebSocket(`ws://127.0.0.1:8000/ws/chat/${conversationId}/`);
    socketClientRef.current.onopen = handleOpen;
    socketClientRef.current.onmessage = handleMessage;
    socketClientRef.current.onerror = handleError;
    socketClientRef.current.onclose = handleClose;
  }, [conversationId, dispatch]);

  useEffect(() => {
    if (conversationId) {
      connectWebSocket();
    }

    const handleVisibilityChange = () => {
      if (!document.hidden && (!socketClientRef.current || socketClientRef.current.readyState !== WebSocket.OPEN)) {
        console.log('Page is now visible. Attempting to reconnect...');
        setTimeout(connectWebSocket, 3000);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      socketClientRef.current?.close();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [conversationId, connectWebSocket]);

  const adminId = admins?.id;

  const onSend = (data: any) => {
    if (!socketClientRef.current) return;

    let params: any = {
      conversation: conversationId,
      text: data.text,
      time: new Date().toISOString(),
      image: data.image,
      newimage: data.newimage,
      attachments: data.attachments,
      meta: {
        receiver: adminId,
        sender: conversationId,
      },
    };

    if (replyData) {
      params["replyOf"] = replyData;
    }

    socketClientRef.current.send(JSON.stringify({ params }));
    setReplyData(null);
  };

  useEffect(() => {
    if (isUserMessageSent || isMessageDeleted || isMessageForwarded || isUserMessagesDeleted || isImageDeleted) {
      dispatch(getChatUserConversations(conversationId));
    }
  }, [
    dispatch,
    isUserMessageSent,
    chatUserDetails,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
    conversationId
  ]);

  const onDeleteMessage = (messageId: string | number) => {
    dispatch(deleteMessage(chatUserDetails.id, messageId));
  };

  const onDeleteUserMessages = () => {
    dispatch(deleteUserMessages(chatUserDetails.id));
  };

  return (
    <>
      <UserHead
        chatUserDetails={chatUserDetails}
        onDelete={onDeleteUserMessages}
      />
      <Conversation
        chatUserConversations={chatConversation}
        chatUserDetails={admins}  // last change from chatUserDetails to admins
        onDelete={onDeleteMessage}
        onSetReplyData={onSetReplyData}
      />
      <ChatInputSection
        onSend={onSend}
        replyData={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
      />
    </>
  );
};

export default Index;