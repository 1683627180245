import React, { useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

interface ChatUserProps {
  admin: any;
  isOnline?: boolean;
  selectedChat: string | number;
  onSelectChat: (id: number | string) => void;
}
const ChatUser = React.memo(({ admin, isOnline = false, selectedChat, onSelectChat }: ChatUserProps) => {
  const avatar = admin?.avatar;
  const fullName = `${admin?.first_name} ${admin?.last_name}`;
  
  const shortName = admin ? `${admin.first_name.charAt(0)}${admin.last_name.charAt(0)}` : "-" ;

  const colors = [
    "bg-primary",
    "bg-danger",
    "bg-info",
    "bg-warning",
    "bg-secondary",
    "bg-pink",
    "bg-purple",
  ];
  const [color] = useState(Math.floor(Math.random() * colors.length));
  const unRead = admin?.meta && admin?.meta.unRead;

  const isSelectedChat: boolean =
    selectedChat && selectedChat === admin.id ? true : false;

  // const onClick = () => {
  //   onSelectChat(user.id);   //onClick={onClick}
  // };

  return (
    <li className={classnames({ active: isSelectedChat })}>
      <Link to="#" className={classnames({ "unread-msg-user": unRead })}>
        <div className="d-flex align-items-center">
          <div
            className={classnames(
              "chat-user-img",
              "align-self-center",
              "me-2",
              "ms-0",
              "user-profile-image",
              { online: isOnline }
            )}
          >
            {avatar && avatar !== '' ? (
              <>
                <img
                  src={avatar}
                  className="rounded-circle avatar-xs"
                  alt=""
                />
                <span className="user-status"></span>
              </>
            ) : (
              <div className="avatar-xs">
                <span
                  className={classnames(
                    "avatar-title",
                    "rounded-circle",
                    "text-uppercase",
                    "text-white",
                    colors[color]
                  )}
                >
                  <span className="username">{shortName}</span>
                  <span className="user-status"></span>
                </span>
              </div>
            )}
          </div>
          <div className="overflow-hidden">
            <p className="text-truncate fs-5 mb-0">{fullName}</p>
          </div>
          {unRead && unRead !== 0 ? (
            <div className="ms-auto">
              <span className="badge badge-soft-dark rounded p-1">
                {unRead}
              </span>
            </div>
          ) : null}
        </div>
      </Link>
    </li>
  );
});

export default ChatUser;

