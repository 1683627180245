import React, { useEffect } from "react";
import "./assets/scss/theme.scss";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { changelayoutMode } from "./redux/actions";
import Routes from "./routes";

const App = () => {
  const dispatch = useDispatch();

  // Use shallowEqual to compare layoutMode to prevent unnecessary re-renders
  const { layoutMode } = useSelector(
    (state: any) => ({
      layoutMode: state.Layout.layoutMode,
    }),
    shallowEqual // This ensures that only if layoutMode changes, the component will re-render
  );

  // Dark/Light Mode
  useEffect(() => {
    const getLayoutMode = sessionStorage.getItem("layoutMode");
    if (getLayoutMode) {
      dispatch(changelayoutMode(getLayoutMode));
    } else {
      dispatch(changelayoutMode(layoutMode));
    }
  }, [layoutMode, dispatch]);

  return <Routes />;
};

export default App;