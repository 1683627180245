import { useState, useEffect } from "react";
import { useRedux } from "../hooks/index";
import { divideByKey } from "../utils";

interface UseAuthenticationReturn {
  loggedIn: boolean;
  userLoading: boolean;
  error: string | null;
}

const useAuthentication = (): UseAuthenticationReturn => {
  const { useAppSelector } = useRedux();

  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [userLoading, setUserLoading] = useState<boolean>(true);

  // Type the state from Redux using RootState
  const { loading, error } = useAppSelector(state => ({
    loading: state.Register?.loading,
    error: state.Register.error,
  }));

  useEffect(() => {
    const userStatus = sessionStorage.getItem("UserStatus");
    setLoggedIn(userStatus === "loggedIn");
    setUserLoading(loading);
  }, [loading]);

  return { loggedIn, userLoading, error };
};



const useContacts = () => {
  const { useAppSelector } = useRedux();

  const { contactsList } = useAppSelector(state => ({
    contactsList: state.AllUsers.contacts,
  }));

  const [categorizedContacts, setCategorizedContacts] = useState([]);

  useEffect(() => {
    if (contactsList.length > 0) {
      const formattedContacts = divideByKey("first_name", contactsList);
      setCategorizedContacts(formattedContacts);
    }
  }, [contactsList]);

  const totalContacts = (categorizedContacts || []).length;
  return { categorizedContacts, totalContacts };
};


const useConversationUserType = () => {
  // global store
  const { useAppSelector } = useRedux();

  const { chatUserDetails } = useAppSelector(state => ({
    chatUserDetails: state.Chats.chatUserDetails,
  }));

  return { chatUserDetails };
};
export { useAuthentication, useContacts, useConversationUserType };
