import { all } from "@redux-saga/core/effects";

//auth
import registerSaga from "./auth/saga";
import LayoutSaga from "./layout/saga";
import contactsSaga from "./allusers/saga";
import chatsSaga from "./chats/saga";

export default function* rootSaga() {
  yield all([
    registerSaga(),
    LayoutSaga(),
    contactsSaga(),
    chatsSaga(),
  ]);
}
