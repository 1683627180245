import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const postLogin = (data: any) => api.create(url.POST_LOGIN, data);

// Register Method
const registerUser = (data: any) => {
  return api.create(url.POST_REGISTER, data);
};

// postSocialLogin
// const postSocialLogin = (data: any) => api.create(url.SOCIAL_LOGIN, data);

export {
  postLogin,
  registerUser,
  // postSocialLogin,
};
