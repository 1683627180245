import { AuthRegisterActionTypes } from "./types";

// common success
export const registerApiResponseSuccess = (
  actionType: string,
  data: any
) => ({
  type: AuthRegisterActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const registerApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: AuthRegisterActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const registerUser = (user: any, navigate: (path: string) => void) => {
  return {
    type: AuthRegisterActionTypes.REGISTER_USER,
    payload: { user, navigate },
  };
};

export const logoutUser = () => {
  return {
    type: AuthRegisterActionTypes.LOGOUT_USER,
  };
};

// export const socialLogin = (data: any, type: "facebook" | "google") => {
//   return {
//     type: AuthRegisterActionTypes.SOCIAL_LOGIN,
//     payload: { data, type },
//   };
// };
