
export const api = {

  API_URL: "https://www.metaversityai.ai/api/",
  //API_URL: "http://127.0.0.1:8000/api/",

  REACT_APP_API_URL: "https://www.metaversityai.ai",
  //REACT_APP_API_URL: "http://127.0.0.1:8000",

  REACT_APP_WEBSOCKET_URL: "wss://www.metaversityai.ai",
  //REACT_APP_WEBSOCKET_URL: "ws://127.0.0.1:8000"

};
