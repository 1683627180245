import React, { useEffect, useState } from "react";
import { Navigate, Route, Location } from "react-router-dom";
import { useAuthentication } from "../hooks/index";
import { useDispatch } from "react-redux";
// import { logoutUser } from "../redux/actions";

// Define props for AuthProtected
interface AuthProtectedProps {
  children: any;
  location?: Location;
}

// AuthProtected component
const AuthProtected: React.FC<AuthProtectedProps> = ({ children, location }) => {
  const dispatch = useDispatch();
  const { loggedIn, userLoading } = useAuthentication();
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  useEffect(() => {
    // Update isFirstLoad to false once userLoading becomes false
    if (isFirstLoad && !userLoading) {
      setIsFirstLoad(false);
    }

    // Dispatch logoutUser only if it's not the first load
    // if (!isFirstLoad && !userLoading && !loggedIn) {
    //   dispatch(logoutUser(location));
    // }
  }, [userLoading, loggedIn, dispatch, isFirstLoad]);

  if (userLoading) {
    return <div>Loading...</div>;
  }

  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <>{children}</>;
};

// Define props for AccessRoute
interface AccessRouteProps {
  component: React.ComponentType<any>;
  path: string;
  [key: string]: any; // Allow additional props
}

// AccessRoute component
const AccessRoute: React.FC<AccessRouteProps> = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      element={
        <AuthProtected>
          <Component />
        </AuthProtected>
      }
    />
  );
};

export { AuthProtected, AccessRoute };