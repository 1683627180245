import React, { useEffect, useState, useCallback } from "react";
import {Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useRedux } from "../../../hooks/index";
import { api } from "../../../config";
import Admins from "./Admins";
import { ConversationTypes } from "../../../data/datatypes";
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import {
  getAdmins,
  getUsers,
  getChatUserConversations,
  readConversation,
} from "../../../redux/actions";



interface ProfileImageProps {
  id: string | number | null, 
  chatUserDetails: any;
  onCloseConversation: () => any;
  // onOpenUserDetails: () => any;
  words: string;
}
const ProfileImage = ({
  id,
  chatUserDetails,
  onCloseConversation,
}: ProfileImageProps) => {
  
  const { dispatch, useAppSelector } = useRedux();

  const {
    users,
    admins,
    selectedChat,
  } = useAppSelector(state => ({
    users: state.AllUsers.users?.data[0],
    admins: state.Chats.admins?.data[0],
    selectedChat: state.Chats.selectedChat,
  }));
  // console.log("users in ProfileImage", users)
  // console.log("admins in ProfileImage", admins)
  // console.log("selectedChat in ProfileImage", selectedChat)

  useEffect(() => {
    setTimeout(() => {
      dispatch(getUsers());
    }, 200);
    setTimeout(() => {
      dispatch(getAdmins());
    }, 500);
  }, [dispatch]);
  

  /*
  select chat handeling :
  */

  const senderId = users?.id;
  const adminId = admins?.id;

  const onSelectChat = useCallback( () => {
    if (!senderId || !adminId) {
      // If we don't have senderId or adminId yet, don't do anything.
      //console.warn('onSelectChat called before senderId or adminId are available.');
      return;
    }

    const conversationData: ConversationTypes = {
      conversationId: senderId,
      userId: senderId,
      typingUser: adminId,
      isGroupConversation: false,
    };
    setTimeout(() => {
      dispatch(readConversation(conversationData));
    }, 500)
    setTimeout(() => {
      dispatch(getChatUserConversations(senderId));
      //dispatch(changeSelectedChat(senderId));
    }, 1500)
  }, [dispatch, senderId, adminId]);


  const [onlineUsers, setOnlineUsers] = useState<Record<number | string, boolean>>({}); // Record to track online users by ID
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const userId = users?.id; // Extract the user ID from your Redux store
  // console.log('loggedIn in Chats index', loggedIn)
  // console.log('onlineUsers in Chats index', onlineUsers)

  useEffect(() => {
    const userStatus = sessionStorage.getItem("UserStatus");
    setLoggedIn(userStatus === "loggedIn");
  }, []);

  useEffect(() => {
    if (!loggedIn || !userId) return;

    // Use environment variable for WebSocket URL
    const wsUrl = `${api.REACT_APP_WEBSOCKET_URL}/ws/isonline/${userId}/`;
    const socketClient = new W3CWebSocket(wsUrl);
  
    socketClient.onmessage = (event: any) => {
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'update_user_status_wrapper') {
          const userId = data.user_id;
          const isOnline = data.is_online;
          if (isOnline) {
            setOnlineUsers(prev => ({ ...prev, [userId]: isOnline }));
          } else {
              setOnlineUsers(prev => {
                  const updatedState = { ...prev };
                  delete updatedState[userId];  // Remove the offline user from the state
                  return updatedState;
              });
          }
        } else if (data.type === 'online_users_list') {
          // Convert the list of user IDs to the Record format for the onlineUsers state
          const updatedOnlineUsers = data.online_users.reduce((acc: Record<number | string, boolean>, userId: number | string) => {
            acc[userId] = true; // Set each user ID as online
            return acc;
          }, {});
          setOnlineUsers(updatedOnlineUsers);
        }
      } catch (error) {
        console.error('Error parsing event data:', error);
      }
    };
    return () => {
      socketClient.close();
    };
  }, [loggedIn, users]); 

  // -------------------------------------------------------------- //

  return (
    <div className="d-flex align-items-center">
      {/* <div className="flex-shrink-0 d-block d-lg-none me-2">
        <Link
          to="#"
          onClick={onCloseConversation}
          className="user-chat-remove text-muted font-size-24 p-2"
        >
          <i className="bx bx-chevron-left align-middle"></i>
        </Link>
      </div> */}
      
      <div>
        <Admins
          onlineUsers={onlineUsers}
          selectedChat={selectedChat}
          onSelectChat={onSelectChat}
        />
      </div>
    </div>
  );
};

interface MoreProps {
  onDelete: () => void;
}
const More = ({
  onDelete,
}: MoreProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="none" className="btn nav-btn" type="button">
        <i className="bx bx-dots-vertical-rounded"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem
          className="d-flex justify-content-between align-items-center"
          to="#"
          onClick={onDelete}
        >
          Delete Conversation <i className="bx bx-trash ps-2 text-muted"></i>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
 
interface UserHeadProps {
  chatUserDetails: any;
  onDelete: () => void;
}
const UserHead = ({
  chatUserDetails,
  onDelete,
}: UserHeadProps) => {
  // const { dispatch } = useRedux();
 
  /*
  mobile menu chat conversation close
  */
  return (
    <div className="p-3 p-lg-4 user-chat-topbar">
      <Row className="align-items-center">
        <Col sm={4} className="col-8">
          <ProfileImage
            chatUserDetails={chatUserDetails}
            onCloseConversation={undefined}
            words={""}
            id={""}       
          />
        </Col>
      </Row>
    </div>
  );
};

export default UserHead;
