import { combineReducers } from "redux";

import Register from "./auth/reducer";
import Layout from "./layout/reducer";
import AllUsers from "./allusers/reducer";
import Chats from "./chats/reducer";

export default combineReducers({
  Register,
  Layout,
  AllUsers,
  Chats,
});
