import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

// const getProfileDetails = () => {
//   return api.get(url.GET_USER_PROFILE);
// };

// const getSettings = () => {
//   return api.get(url.GET_USER_SETTINGS);
// };
const updateSettings = (field: string, value: any) => {
  return api.update(url.UPDATE_ETTINGS, {
    field: field,
    value: value,
  });
};


const getProfile = () => {
  return api.get(url.GET_PROFILE);
};
const createUserProfile = (data: object) => {
  return api.create(url.CREATE_USER_PROFILE, data);
};
const editUserProfile = (profileDetails: any) => {
  return api.update(url.EDIT_USER_PROFILE  + profileDetails.id + '/', profileDetails);
};
const deleteUserProfile = (id: number) => {
  return api.delete(url.DELETE_USER_PROFILE + id + '/');
};


export { 
  // getSettings, 
  updateSettings, 
  getProfile, 
  createUserProfile, 
  editUserProfile, 
  deleteUserProfile 
};
