import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { AuthRegisterActionTypes } from "./types";
import {
  registerApiResponseSuccess,
  registerApiResponseError,
} from "./action";
import { registerUser as registerUserApi } from "../../api/index";
import { setWithCredentials } from "../../api/apiCore";


interface User {
  email: string;
  password: string;
}
interface Payload {
  user: User;
  navigate: (path: string) => void;
}
interface Action {
  payload: Payload;
}
// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, navigate } }: Action): Generator<any, void, any> {
  try {
    const response: Promise<any> = yield call(registerUserApi, user);
    
    if ((yield response).status === 201) {
      yield put(registerApiResponseSuccess(AuthRegisterActionTypes.REGISTER_USER, response));

      // Set withCredentials after successful login
      setWithCredentials(true);
      sessionStorage.setItem('UserStatus', 'loggedIn');
      navigate('/');
    } else {
      yield put(registerApiResponseError(AuthRegisterActionTypes.REGISTER_USER, "Invalid credentials. Please try again."));
    }
  } catch (error: any) {
    yield put(registerApiResponseError(AuthRegisterActionTypes.REGISTER_USER, error));
  }
}

function* logoutUser() {
  try {
    sessionStorage.removeItem("authUser");
    yield put(
      registerApiResponseSuccess(AuthRegisterActionTypes.LOGOUT_USER, true)
    );
    
  } catch (error: any) {
    yield put(
      registerApiResponseError(AuthRegisterActionTypes.LOGOUT_USER, error)
    );
  }
}

export function* watchUserRegister(): Generator<any, void, any> {
  yield takeEvery(AuthRegisterActionTypes.REGISTER_USER as any, registerUser);
}

export function* watchUserLogout() {
  yield takeEvery(AuthRegisterActionTypes.LOGOUT_USER, logoutUser);
}

function* registerSaga() {
  yield all([
    fork(watchUserRegister),
    fork(watchUserLogout),
  ]);
}

export default registerSaga;
